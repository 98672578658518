export default {
  name: '이름',
  tel: '핸드폰',
  save: '구하다',
  confirm: '확인',
  cancel: '취소',
  delete: '삭제',
  complete: '완벽한',
  loading: '로딩 중',
  telEmpty: 'TEL을 기입하십시오',
  nameEmpty: '이름을 기입하십시오',
  nameInvalid: '잘못된 이름',
  confirmDelete: '삭제 하시겠습니까?',
  telInvalid: '잘못된 전화 번호',
  vanCalendar: {
    end: '끝',
    start: '시작',
    title: '달력',
    startEnd: '시작 / 끝',
    weekdays: ['일', '월', '화', '수', '목', '금', '토'],
    monthTitle: function monthTitle(year, month) {
      return year + '/' + month;
    },
    rangePrompt: function rangePrompt(maxRange) {
      return '이상을 선택하십시오 ' + maxRange + ' 날';
    },
  },
  vanCascader: {
    select: '제발 선택하십시오',
  },
  vanContactCard: {
    addText: '연락처 정보 추가',
  },
  vanContactList: {
    addText: '새로운 접촉을 추가하십시오',
  },
  vanPagination: {
    prev: '이전의',
    next: '다음',
  },
  vanPullRefresh: {
    pulling: '끌어 당겨서 새로 고침 ...',
    loosing: '새로 고침으로 느슨한 ...',
  },
  vanSubmitBar: {
    label: '총:',
  },
  vanCoupon: {
    unlimited: '제한 없는',
    discount: function discount(_discount) {
      return _discount * 10 + '% off';
    },
    condition: function condition(_condition) {
      return '적어도 ' + _condition;
    },
  },
  vanCouponCell: {
    title: '쿠폰',
    tips: '아니 쿠폰 없음',
    count: function count(_count) {
      return '당신은 가지고 있습니다 ' + _count + ' 쿠폰';
    },
  },
  vanCouponList: {
    empty: '아니 쿠폰 없음',
    exchange: '교환',
    close: '닫다',
    enable: '사용 가능',
    disabled: '없는',
    placeholder: '쿠폰 코드',
  },
  vanAddressEdit: {
    area: '지역',
    postal: '우편 엽서',
    areaEmpty: '수신 영역을 선택하십시오',
    addressEmpty: '주소는 비어 있지 않을 수 있습니다',
    postalEmpty: '잘못된 우편 번호',
    defaultAddress: '기본 주소로 설정하십시오',
    telPlaceholder: '핸드폰',
    namePlaceholder: '이름',
    areaPlaceholder: '지역',
  },
  vanAddressEditDetail: {
    label: '주소',
    placeholder: '주소',
  },
  vanAddressList: {
    add: '새 주소를 추가하십시오',
  },
};
